export default class Config {

	project = null
	configs = {}

	constructor(project) {
		this.project = project
	}

	get id () {
		if(!(this.project in this.configs)) {
			console.error(`Class Congig, ${this.project} not present in configs`)
			return null
		}
		return this.configs[this.project].id
	}

	get GitHubAuth () {
		if(!(this.project in this.configs)) {
			console.error(`Class Congig, ${this.project} not present in configs`)
			return null
		}
		return this.configs[this.project]['github-auth']
	}


	getGETRequestOptions() {
		if(!(this.project in this.configs)) {
			console.error(`Class Congig, ${this.project} not present in configs`)
			return null
		}

		const myHeaders = new Headers()
		myHeaders.append("Authorization", this.configs[this.project].auth)
		return {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		}
	}

	getGitHubGETRequestOptions() {
		if(!(this.project in this.configs)) {
			console.error(`Class Congig, ${this.project} not present in configs`)
			return null
		}

		const myHeaders = new Headers()
		myHeaders.append("Authorization", this.configs[this.project]['github-auth'])
		myHeaders.append('X-GitHub-Api-Version', '2022-11-28')
		myHeaders.append('Accept', 'application/vnd.github+json')
		return {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		}
	}
}

