import React, {useState} from "react"
import './FoldableInformation.css'

const FoldableInformation = ({title, startFolded, canCopyToClipboard, children, actionIcon, actionCallback}) => {
  const [folded, setFolded] = useState(startFolded)

  const onFold = e => {
    setFolded(!folded)
	}

  const onCopyToClipboard = e => {
    e.stopPropagation()
		const log = e.target.getAttribute('arg-logs') || 'Logs not found'
		navigator.clipboard.writeText(log)
	}

  const classes = {
    foldStatus: folded ? 'folded' : 'unfolded',
    hasContent: (children != null && children !== '') ? '' : 'empty'
  }
  const computedClasses = Object.values(classes).join(' ')
  const clipboardSupport = canCopyToClipboard ? (<div className='title-clipboard' onClick={onCopyToClipboard} arg-logs={children != null ? children.toString() : ''}>📋</div>) : null
  const actionSupport = actionIcon!=null ? (<div className='title-action' onClick={actionCallback}>{actionIcon}</div>) : null

  return (
    <div className="FoldableInformation">
      <div className={computedClasses}>
        <div className='title' onClick={onFold}>
          <div className='title-text'>{title}</div>
          {clipboardSupport}
          {actionSupport}
        </div>
        <div className='content'>
          <pre>{children}</pre>
        </div>
      </div>
    </div>
	)
}

export default FoldableInformation