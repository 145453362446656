import React, {useEffect} from 'react'
import { timeSince, getPipelineStatusIcon } from '../Utils/Utils'


const CommitNode = ({commit, commitsManager}) => {
	useEffect(() => {
		commitsManager.getCommitPipelinesAndDifferences(commit)
	}, [commit])

	const onClick = e => {
    e.stopPropagation();
		commitsManager.onSelectCommit(commit)
  }

	let title = commit.commit.message.split(/\r?\n/)[0] // first list of the commit
	if(title.length > 50) {
		title = title.substr(0, 50) + '...'
	}

	const createdAt = new Date(commit.commit.committer.date)

	let pipelinesStatuses = ''
	let pipelineCount = ''
	let differencesStatuses = ''

	if(commit.pipelines != null) {
		pipelinesStatuses = Object.keys(commit.pipelines).reduce((res, k) => {
			return res + getPipelineStatusIcon(commit.pipelines[k].status)
		}, '')

		pipelineCount = ` (${Object.keys(commit.pipelines).length} pipelines)` 
		if(pipelinesStatuses === '') {
			pipelinesStatuses = 'NA'
		}
	}

	if(commit.differences != null) {
		differencesStatuses = Object.keys(commit.differences).reduce((res, k) => {
			switch (commit.differences[k].Status) {
				case 'differences':
					return res + '🟨'
				case 'empty':
					return res + '⬜️'
				default:
					return res + '🟩'
			}
		}, '')
	}

	const isSelected = (commitsManager.selectedCommit != null && commitsManager.selectedCommit === commit)

  return (
    <div className={isSelected ? "CommitNode selected" : "CommitNode"} key={commit.id} onClick={onClick}>
      <a className='giturl' href={commit.web_url} target="_blank" rel="noopener noreferrer">🔗</a><div className='cardname' title={commit.message}>{title}</div>
			<div className='line'>
				<div className='author'>{commit.committer_name}</div>
			</div>
			<div className='line'>
				<div className='datetime'>{createdAt.toLocaleString()}</div>
				<div className='timeago'>{`(${timeSince(createdAt)} ago)`}</div>
			</div>
			<div className='line'>
				<div className='pipelines_laststatus'>Statuses:{pipelinesStatuses}{pipelineCount}</div>
			</div>
			<div className='line'>
				<div className='pipelines_laststatus'>Images Differences:{differencesStatuses}</div>
			</div>
			<div className='line'>
				<div className='sha'>SHA:{commit.sha}</div>
			</div>
    </div>
  )
}

export default CommitNode