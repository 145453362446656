import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Amplify } from 'aws-amplify';
import { Authenticator, Heading, View, Image, useTheme } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';

import NavBar from './NavBar/NavBar';
import AutotestsPage from './AutotestsPage/IndexAutotestsPage'
import ConfigsPage from './ConfigsPage/IndexConfigsPage';


import './Style.css'
import '@aws-amplify/ui-react/styles.css';
import './App.css'

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Implicit Conversions logo" src="https://www.implicitconversions.com/wp-content/uploads/2022/09/ic-logo-purple.png" />
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={6} >
          SignIn with your Implicit Conversions Google account
        </Heading>
      );
    }
  }  
}

function App() {
  // const isDev = process.env.NODE_ENV != null && process.env.NODE_ENV === 'development'

  return (
    <Authenticator hideSignUp={true} components={components}>
      {({ signOut, user }) => (
        <Router>
          <div id="App">
            <NavBar {...{signOut, user}} />
            <Routes>
              <Route path="/about" element={<About/>} />
              <Route path="/configs" element={<ConfigsPage/>} />
              <Route path="/tests" element={<AutotestsPage/>} />
              <Route path="/" element={<AutotestsPage/>} />
            </Routes>
          </div>
        </Router>
      )}
    </Authenticator>
  )
}

const About = () => {
  return (
    <div>About</div>
  )
}


export default App;
