import React from 'react'
import CommitNode from './CommitNode'
import Spinner from '../Utils/Spinner'
import InfiniteScroll from 'react-infinite-scroll-component'

import './CommitList.css'

const CommitList = ({branchesMananger, commitsManager}) => {
  const nodes = commitsManager.commits.map(c => {
    return (
      <CommitNode 
        key={c.sha}
        commit={c} 
        commitsManager={commitsManager} 
    />
    )
  })

  const branchesOptions = branchesMananger.branches.map(b => (<option key={b.name} value={b.name}>{b.name}</option>))

  const onBranchChange = e => {
    branchesMananger.onSelectBranch(e.target.value)
  }

  return (
    <div className="CommitList" id="CommitList">
      <div className='Branch'>
        <div className='title'>Branches: 
          <select name='Branches' onChange={onBranchChange} value={branchesMananger.selectedBranch}>
            {branchesOptions}
          </select>
        </div>
      </div>

      <div className='Commits'>
        <div className='title'>Commits</div>

        <InfiniteScroll
          dataLength={nodes.length}
          next={commitsManager.getMoreCommits}
          hasMore={true}
          loader={<Spinner/>}
          scrollableTarget="CommitList"
        >
          {nodes}
        </InfiniteScroll>

      </div>
    </div>
  )
}

export default CommitList