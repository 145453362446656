import React from 'react'
import FoldableInformation from '../AutotestsPage/FoldableInformation'
import './Filters.css'

const Filters = ({filters, onUpdateFilters}) => {

	const onChange = e => {
		const name = e.target.getAttribute('name')
		if(name != null) {
			filters[name] = e.target.value
		}
		onUpdateFilters({...filters})
	}

	const filtersComponents = []
	const keys = Object.keys(filters)
	for(const k of keys) {
		const f = (<div key={k} className='filterblock'>
			<div className='label'>{k}:</div>
			<input type='text' name={k} value={filters[k]} onChange={onChange} />
		</div>)
		filtersComponents.push(f)
	}


	return (
		<FoldableInformation title='Filters' startFolded={false} canCopyToClipboard={false}>
			<div className='Filters'>
				{filtersComponents}
			</div>
		</FoldableInformation>
  )
}

export default Filters