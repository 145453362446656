/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:3262014d-51b1-44ed-aabd-2d1cd0e2a38a",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_8yOw4CMHt",
    "aws_user_pools_web_client_id": "5dfd8ilg3t90hphmuoc9jbk1kq",
    "oauth": {
        "domain": "atfe0f893a2b-0f893a2b-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://autotesting.implicitconversions.com/",
        "redirectSignOut": "https://autotesting.implicitconversions.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "atfe-prod-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://do6bej55cme4t.cloudfront.net"
};


export default awsmobile;
