import React, {useState, useEffect} from 'react'
import FoldableInformation from './FoldableInformation'
import CompareImageDifference from './CompareImageDifference'
import CompareImageLoading from './CompareImageLoading'
import CompareImageSideBySide from './CompareImageSideBySide'
import CompareImageSlider from './CompareImageSlider'
import ReferenceImagePicker from './ReferenceImagePicker'

import {getReferenceImagePathfromS3Key, findReferencesForKey, arrayEquals} from '../Utils/Utils'

import './ImageInspector.css'

const ImageInspector = ({show, selectedImage, onHideImageInspector, s3ImageManager, urlManager}) => {
  const [toolType, setToolType] = useState(urlManager.getURL().options['imageinspectortype'] ||  'horizontal')

  const [loadingImgKey, setLoadingImgKey] = useState(null)
  const [imgTest, setImgTest] = useState(null)

  const [loadingImgReference, setLoadingImgReference] = useState(null)
  const [selectedReference, setSelectedReference] = useState(null)
  const [imgReference, setImgReference] = useState(null)
  const [arrayReferences, setArrayReferences] = useState([])

  const resetState = () => {
    if(loadingImgKey != null) {
      setLoadingImgKey(null)
      setImgTest(null)

      setLoadingImgReference(null)
      setImgReference(null)
      setArrayReferences([])
      setSelectedReference(null)
    }
  }

  // Loading of the Test image
  useEffect(() => {
    if(selectedImage == null) {
      return
    }

    if(loadingImgKey !== selectedImage.key) {
      setImgTest(null)
      setLoadingImgKey(selectedImage.key)
      setSelectedReference(null)
      return
    }

    if(imgTest == null && selectedImage.key in s3ImageManager.S3Images) {
      const loadTest = new Image()
      loadTest.onload = () => {
        setImgTest(loadTest)
      }
      loadTest.id = selectedImage.key
      loadTest.src = s3ImageManager.S3Images[selectedImage.key]
    }
  }, [ selectedImage, loadingImgKey, imgTest, s3ImageManager.S3Images])


  // Loading of the references
  useEffect(() => {
    if(selectedImage == null || loadingImgKey == null) {
      return
    }

    // Get the default reference AND the user references
    if(loadingImgKey.search(/\.log$/) === -1) {
      const refs = []
      refs.push(getReferenceImagePathfromS3Key(loadingImgKey))
      refs.push(...findReferencesForKey(s3ImageManager.S3UserReferences, loadingImgKey))
      if(!arrayEquals(refs, arrayReferences)) {
        setArrayReferences(refs)
        if(selectedReference == null) {
          setSelectedReference(refs[0])
        }
      }

      const referenceToLoad = selectedReference ?? refs[0]
      if(loadingImgReference !== referenceToLoad) {
        setImgReference(null)
        setLoadingImgReference(referenceToLoad)
        return
      }

      if(imgReference == null && referenceToLoad in s3ImageManager.S3Images) {
        const loadRef = new Image()
        loadRef.onload = () => {
          setImgReference(loadRef)
        }
        loadRef.id = referenceToLoad
        loadRef.src = s3ImageManager.S3Images[referenceToLoad]
      }
    }
  }, [selectedImage, arrayReferences, loadingImgKey, loadingImgReference, selectedReference, imgReference, s3ImageManager.S3Images, s3ImageManager.S3UserReferences])


  const onChangeToolType = e => {
    const typeMapping = {'Horizontal Slider':'horizontal', 'Side by Side':'sidebyside', 'Difference':'difference'}
    const value = typeMapping[e.target.innerText] ?? 'horizontal'
    if(value !== toolType) {
      setToolType(value)
      urlManager.setURL({options:{imageinspectortype:value}})
    }
  }

  const onUpdateReference = () => {
    const keyRef = getReferenceImagePathfromS3Key(selectedImage.key)
    if(window.confirm('Warning, this will overwrite the current reference image with the image of the test. This will impact all future tests. Do you want to proceed?')) {
      s3ImageManager.updateS3Reference(selectedImage.key, keyRef)
      .then(
        _data => {
          setImgReference(imgTest)
        },
        error => {
          console.error('UpdateReference error', error)
        }
      )
    }
  }

  const onSelectReference = newSelectedReference => {
    setSelectedReference(newSelectedReference)
  }

  const onExit = () => {
    document.removeEventListener('keyup', onKeyUp)
    resetState()
    onHideImageInspector()
  }

  const onKeyUp = e => {
    if(e.key === 'Escape') {
      onExit()
      e.stopImmediatePropagation()
    }
  }

  // Grab the ESC key
  if(show === true) {
    document.addEventListener('keyup', onKeyUp)
  }

  let imageElements = null
  if(imgReference != null && imgTest != null) {
      if(toolType === 'horizontal') {
        imageElements = <CompareImageSlider leftLabel="Reference" leftImage={imgReference} rightLabel="Test" rightImage={imgTest} />
      } else if (toolType === 'sidebyside') {
        imageElements = <CompareImageSideBySide leftLabel="Reference" leftImage={imgReference} rightLabel="Test" rightImage={imgTest} />
      } else {
        imageElements = <CompareImageDifference imgFirst={imgReference} imgSecond={imgTest} />
      }
  } else {
    if(loadingImgKey != null && loadingImgKey.search(/\.log$/) !== -1) {
      // Log ONLY viewer
      imageElements = ( <div> <div>There are no test images for this entry, this is ONLY a log view</div> </div> )
    } else {
      imageElements = <CompareImageLoading leftLabel="Reference" leftImage={imgReference} rightLabel="Test" rightImage={imgTest} />
    }
  }

  let buttonUpdateReference = null
  if(imgReference != null && imgTest != null) {
    buttonUpdateReference = (<div className='button critical' onClick={onUpdateReference}>Update Reference</div>)
  }

  const logInfo = `Reference image key: ${getReferenceImagePathfromS3Key(loadingImgKey)}\nTest image key: ${loadingImgKey}`
  const logs = []
  if(loadingImgKey != null) {
    const logsTypes = ['output', 'error', 'stacks']
    const logsPath = loadingImgKey.split('/',3).join('/')
    for(const t of logsTypes) {
      const logkey = `${logsPath}/${t}.log`
      let data = s3ImageManager.S3Images[logkey] || null
      if(data == null) {
        data = 'Log file not present on S3'
      }
      const newlog = (<FoldableInformation key={logkey} title={t} startFolded={false} canCopyToClipboard={true}>{data}</FoldableInformation>)
      logs.push(newlog)
    }
  }

  return (
    <div className={`ImageInspector ${show ? 'show' : 'hide'}`}>
      <div className='backgroud-filter' onClick={onExit} />
      <div className='dialog'>

        <div className='dialog-title'>
          <div>Image Inspector</div>
          <div className='exitbutton' onClick={onExit}>X</div>
        </div>

        <div className='tools'>
          <div className='header'>
            Tools
          </div>
          <div className='buttongroup'>
            <div className={'button left horizontal' + (toolType==='horizontal' ? ' selected' : '')} onClick={onChangeToolType}>Horizontal Slider</div>
            <div className={'button center sidebyside' + (toolType==='sidebyside' ? ' selected' : '')} onClick={onChangeToolType}>Side by Side</div>
            <div className={'button right difference' + (toolType==='difference' ? ' selected' : '')} onClick={onChangeToolType}>Difference</div>
          </div>
          {buttonUpdateReference} 
        </div>

        <div className='dialog-content'>
          {imageElements}
        </div>

        <div className='dialog-information'>
          <ReferenceImagePicker selectedImageKey={loadingImgKey} selectedReference={selectedReference} references={arrayReferences} onSelectReference={onSelectReference} startFolded={false} s3ImageManager={s3ImageManager}/>
          <FoldableInformation title="Information" startFolded={false} canCopyToClipboard={true}>{logInfo}</FoldableInformation>
          {logs}
        </div>
      </div>
    </div>
  )
}

export default ImageInspector