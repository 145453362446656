
import {useEffect} from 'react'

// This is an override of useEffect to be ran only once at the component creation.
// This is handled by passing the empty array to useEffect, so nothing will trigger it again.
// The next line is to disable a warning that says that the 'handler' should be in the array
// of dependencies, but doing so, would call the handler at every frame. We just disable this 
// warning, ONCE, here.
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = handler => useEffect(handler, []);

export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export function arrayEquals(arr1, arr2) {
  return (arr1.length === arr2.length) && arr1.every(function(element, index) {
    return element === arr2[index]; 
  })
}

export function objectEquals(obj1, obj2) {
  const k1 = Object.keys(obj1)
  const k2 = Object.keys(obj2)

  if(k1.length !== k2.length) {
    return false
  }

  for(const k of k1) {
    if(!(k in obj2)) {
      return false
    }

    if(Array.isArray(obj1[k])){
      if(!Array.isArray(obj2[k])) {
        return false
      }
      if(!arrayEquals(obj1[k], obj2[k])) {
        return false
      }
    } else if((typeof obj1[k] === 'function') || (typeof obj1[k] === 'object')) {
      if(!((typeof obj2[k] === 'function') || (typeof obj2[k] === 'object'))) {
        return false
      }
      if(!objectEquals(obj1[k], obj2[k])) {
        return false
      }
    } else {
      if(obj1[k] !== obj2[k]) {
        return false
      }
    }
  }
  return true
}

export function objectIncludesStringNoCase(obj, string) {
  if(obj == null || string == null) {
    return false
  }

  string = string.toLowerCase().trim()
  if(string === '') {
    return false
  }

  if(typeof obj === 'string') {
    return obj.toLocaleLowerCase().includes(string)
  } else if(Array.isArray(obj)){
    for(const a of obj) {
      if(objectIncludesStringNoCase(a, string)) {
        return true
      }
    }
  } else if((typeof obj === 'function') || (typeof obj === 'object')) {
    const keys = Object.keys(obj)
    for(const k in keys) {
      if(k.toLocaleLowerCase().includes(string)) {
        return true
      }

      if(objectIncludesStringNoCase(obj[k], string)) {
        return true
      }
    }
  }
  return false
}

export function getPlatformFromS3Key(key) {
  if(key == null) return 'NA'
  if(key.indexOf('/') !== -1) {
    // Key is from S3 and has /'s 
    const fullname = key.split('/')[2]
    return fullname.split('_')[0]
  } else {
    //key is from DB, /'s are _
    return key.split('_')[3]
  }
}

export function getZoneFromS3Key(key) {
  if(key == null) return 'NA'
  if(key.indexOf('/') !== -1) {
    // Key is from S3 and has /'s 
    const fullname = key.split('/')[2]
    return fullname.split('_')[1]
  } else {
    //key is from DB, /'s are _
    return key.split('_')[3]
  }
}

export function getZoneFlagFromS3Key(key) {
  if(key == null) return 'NA'
  const zone = getZoneFromS3Key(key)
  const zones = {'SIEA':'🇺🇸', 'SIEE':'🇪🇺', 'SIEJ':'🇯🇵'}

  if(zone in zones) {
    return zones[zone]
  }
  return '🏴‍☠️'
}

export function getLongTitleFromS3Key(key) {
  if(key == null) return 'NA'
  if(key.includes('_snapshots_entrypoint_')) {
    return key.match(/_snapshots_entrypoint_(.*)_entrypoint/)[1]
  } else if(key.includes('_snapshots_frame_')) {
    return key.match(/_snapshots_frame_\d+_(.*)_frame_/)[1]
  } else if(key.includes('_snapshots_manuals_')) {
    return key.match(/_snapshots_manuals_(.*)(\/screenshots|_screenshots|\.)/)[1]
  }
  
  return 'notitle'
}

export function getLongTitleCleanFromS3Key(key) {
  if(key == null) return 'NA'
  let longTitle = getLongTitleFromS3Key(key)

  return longTitle.replaceAll('_', ' ')
}

export function getShortTitleFromS3Key(key) {
  if(key == null) return 'NA'
  const longTitle = getLongTitleFromS3Key(key)

  let shortTitle = longTitle.split('_')[0]

  shortTitle = shortTitle.charAt(0).toUpperCase() + shortTitle.slice(1)
  
  //Split numbers from title
  shortTitle = shortTitle.split(/(\d+)/).join(' ')

  //Split by Cap letters
  return shortTitle.split(/(?=[A-Z])/).join(' ').trim()
}

export function getImagePathWithoutIDfromS3Key(key) {
  if(key == null) return 'NA'
  const idx = key.indexOf('/pytest_results')
  return key.substring(idx)
}

export function getReferenceImagePathfromS3Key(key) {
  return 'References' + getImagePathWithoutIDfromS3Key(key)
}

export function getUserReferenceImagePathfromS3Key(key, suffix='') {
  return 'UserReferences' + getImagePathWithoutIDfromS3Key(key) + suffix
}

export function findReferencesForKey(references, key) {
  const res = [];
  const keyPath = getImagePathWithoutIDfromS3Key(key)
  if(references != null) {
    for(const rKey of Object.keys(references)) {
      if(rKey.search(keyPath) !== -1) {
        res.push(rKey)
      }
    }
  }
  return res
}

export function getPipelineStatusIcon(status) {
  switch(status) {
    case 'warning':
    case 'action_required':
      return '⚠️'

    case 'completed':
    case 'success':
      return '✅'

    case 'cancelled':
    case 'failure':
    case 'timed_out':
      return '🛑'

    case 'in_progress':
      return '🏃‍♂️'

    case 'queued':
    case 'requested':
    case 'waiting':
    case 'pending':
      return '⏳'
    
    default:
      return '❓'
  } 
}

export function getPipelineAdjustedIID(pipeline) {
  return 10000 + pipeline.run_number
}