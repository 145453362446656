import React, {useState} from "react"

const CollapsibleImageTreeLine = ({indent, additionalClasses, title, hasDifference, startFolded, children, onToggleFolded}) => {
  const [folded, setFolded] = useState(startFolded)

  const onFold = e => {
		const newFolded = !folded
    setFolded(newFolded)
		if(onToggleFolded != null) {
			onToggleFolded(newFolded)
		}

	}

	const arrow = folded ? '➡️ ' : '⬇️ '
  const classes = {
    additionalClasses: additionalClasses,
    foldStatus: folded ? 'folded' : 'unfolded',
    hasContent: (children != null && children !== '') ? '' : 'empty',
		indent: `indent-${indent}`,
		hasDifference: hasDifference ? 'hasdifference' : ''
  }
  const computedClasses = Object.values(classes).join(' ')

  return (
    <div className="CollapsibleImageTreeLine">
      <div className={computedClasses}>
        <div className='title' onClick={onFold}>{arrow}{title}</div>
        <div className='content'>
					{children}
        </div>
      </div>
    </div>
	)
}

export default CollapsibleImageTreeLine
