import React, { useState, useEffect } from 'react'
import VariablesCard from './VariablesCard'
import TestsList from './TestsList'
import Filters from './Filters'
import {objectIncludesStringNoCase} from '../Utils/Utils'

import './ConfigPanel.css'

const ConfigPanel = ({settings, config, tests, onUpdateTest, onAddTest, onUpdateVariables}) => {
  const [filteredTests, setFilteredTests] = useState([])
  const [filters, setFilters] = useState({
    name:'', 
    tags:'',
    platform :'',
    region: '',
    type:'',
    sub_type:'',
    snapshot_path:'',
    image_path:'',
    comments:''
  })

  useEffect(() => {
    if(tests != null) {
      // prep filters
      const keys = Object.keys(filters)
      const workfilters = {}
      for(const k of keys) {
        let v = filters[k]
        v = v.toLowerCase()
        v = v.trim()
        v = v.replace(/\s+/, ' ')
        workfilters[k] = v
      }
  
      const filtered = tests.filter(t => {
        for(const k of keys) {
          if(workfilters[k] !== '' && k in t) {
            if(objectIncludesStringNoCase(t[k], workfilters[k]) === false) {
              return false
            }
          }
        }
        return true
      })

      setFilteredTests([...filtered])
    }
  }, [config, tests, filters])

  let variables = null
  if(config != null && 'variables' in config) {
    variables = <VariablesCard variablesSettings={config.variables} onUpdateVariables={onUpdateVariables}/>
  }

  let testsComponent = null
  if(filteredTests.length > 0) {
    testsComponent = <TestsList settings={settings} tests={filteredTests} onUpdateTest={onUpdateTest} onAddTest={onAddTest}/>
  }

  let filtersComponent = null
  if(tests != null) {
    filtersComponent = <Filters filters={filters} onUpdateFilters={setFilters} />
  }

  return (
    <div className='ConfigPanel'>
      {variables}
      {filtersComponent}
      {testsComponent}
    </div>
  )
}

export default ConfigPanel