import React from "react"
import shajs from 'sha.js'

import {getZoneFlagFromS3Key, getReferenceImagePathfromS3Key, findReferencesForKey, useMountEffect} from '../Utils/Utils'

import './ImageThumbnail.css'

const ImageThumbnail = ({IID, game, platform, s3File, isLogOnly, differencesManager, s3ImageManager, onSetSelectedImage, urlManager}) => {

  useMountEffect(() => {
    // Load the thumbnail
    if(s3File.Key.search(/\.log$/) === -1) {
      s3ImageManager.loadS3Images(s3File.Key)
    }

    const url = urlManager.getURL()
    const urlImgID = url.path.imgid
    const urlImgKey = url.path.imgkey
    if(urlImgID != null && urlImgKey != null) {
      loadTestAndRefenceImages()
    }
  })

  const loadTestAndRefenceImages = () => {
    let imgkey = s3File.Key
    if(imgkey.search(/\.log$/) === -1) {
      // Normal case, this is an image to load, not a log file
      imgkey = imgkey.replace('_thumb.png', '.png')

      s3ImageManager.loadS3Images(imgkey)

      const refs = []
      refs.push(getReferenceImagePathfromS3Key(imgkey))
      refs.push(...findReferencesForKey(s3ImageManager.S3UserReferences, imgkey))

      for(const r of refs) {
        s3ImageManager.loadS3Images(r)
      }
    }

    // Fetch all the logs
    const logsTypes = ['output', 'error', 'stacks']
    const logsPath = s3File.Key.split('/',3).join('/')
    for(const t of logsTypes) {
      const logkey = `${logsPath}/${t}.log`
      s3ImageManager.loadS3Images(logkey)
    }

    return imgkey
  }

  const onImageClick = e => {
    e.stopPropagation();

    const imgkey = loadTestAndRefenceImages()
    const imgID = 's3img_'+shajs('sha256').update(imgkey).digest('hex')
    // contextRoot.setURL({path:{pipeline:props.IID, title:props.title, platform:props.platform, imgid:imgID, imgkey:imgkey}})
    onSetSelectedImage(IID, game, platform, imgID, imgkey)
  }

  // Our S3 images are not URLs, they are Base64 strings in memory, because they
  // are loaded securely from S3. Adding these in a loop is slow and blocky for the
  // browser, so with this timeout callback set at 1ms, it will allow the browser
  // to contue running, while we will go as fast as possible to insert these images.
  const onAsyncImageInsert = (id, key) => {
    const element = document.querySelector(`#${id}`)

    if(element != null) {
      element.innerHTML = `<img src="${s3ImageManager.S3Images[key]}"/>`
    }
  }

  const id = 's3img_'+shajs('sha256').update(s3File.Key).digest('hex')
  if(isLogOnly === false && s3File.Key in s3ImageManager.S3Images) {
    setTimeout(onAsyncImageInsert, 1, id, s3File.Key)
  }

  const differences = differencesManager.doesElementHaveDifference(IID, platform, game, s3File.Key)
  let hasDifference = ''
  let tooltip = ''
  if(isLogOnly === false && differences !== 'ok') {
    hasDifference = 'hasdifference'
    tooltip = 'This image as some differences relative to the reference image.'
    if(differences === 'referenceupdated') {
      hasDifference = 'hasdifference referenceupdated'
      tooltip = 'This image had some differences relative to the reference image. But the reference image was updated after this test ran.'
    }
  }

  const title = isLogOnly ? 'Logs' : s3File.Key
  const flag = getZoneFlagFromS3Key(s3File.Key)
  const tmpThumbnail = isLogOnly ? (<img alt="Log icon" className="log" src="document-256x256.png"/>) : 'loading'

  return (
    <div className='ImageThumbnail' title={tooltip}>
      <div className={hasDifference}>
        <div className="thumbnail" id={id} onClick={onImageClick}>{tmpThumbnail}</div>
        <div className="flag">{flag}</div>
        <div className="title" title={title}>{title}</div>
      </div>
    </div>
  )
}

export default ImageThumbnail
