import React, {useEffect, useState} from 'react'
import FoldableInformation from '../AutotestsPage/FoldableInformation'
import {objectEquals} from '../Utils/Utils'

import './VariablesCard.css'

const VariablesCard = ({variablesSettings, onUpdateVariables}) => {
	const [isDirty, setIsDirty] = useState(false)
	const [variables, setVariables] = useState({...variablesSettings})

	useEffect(() => {
		setIsDirty(!objectEquals(variablesSettings, variables))
	}, [variablesSettings, variables])


	const onChangeVariables = e => {
		variables[e.target.name] = e.target.value
		setVariables({...variables})
	}

	const onPushUpdate = () => {
		onUpdateVariables(variables)
	}

	const onAddVariable = e => {
		e.stopPropagation()
		let newVar = window.prompt('Enter a new variable name')

		if(newVar != null) {
			newVar = newVar.replace(/\s/gi, '_');
			newVar = newVar.replace(/[^a-z0-9_]/gi, '');
			newVar = newVar.replace(/_+/gi, '_');

			if(newVar.length > 0 && !(newVar in variables)) {
				variables[newVar] = ''
				setVariables({...variables})
			}
		}
	}

	const onDeleteVariable = e => {
		delete variables[e.target.name]
		setVariables({...variables})
	}


	let form = null
	if(variables != null) {
		const lines = []

		for(const k of Object.keys(variables)) {
			const line = (
				<div className='row' key={k}>
					<div className='label'>
						{`${k}:`}
					</div>
					<input type='text' name={k} value={variables[k]} onChange={onChangeVariables} />
					<img className='deleteicon' alt='Delete variable' src='minus-red-256x256.png' name={k} onClick={onDeleteVariable}/>
				</div>
			)
			lines.push(line)
		}

		let updateButton = null
		if(isDirty) {
			updateButton = (<div className='button' onClick={onPushUpdate}>Update</div>)
		}

		form = (
			<div className={isDirty ? 'VariablesCard dirty' : 'VariablesCard'}>
				{lines}
				{updateButton}
			</div>
		)
	}

	const addIcon = (<img className='addicon' alt='Add a new variable' src='add-256x256.png' />)

	return (
		<div className='VariableCard'>
			<FoldableInformation title='Variables' startFolded={false} canCopyToClipboard={false} children={form} actionIcon={addIcon} actionCallback={onAddVariable}/>
		</div>
  )
}

export default VariablesCard