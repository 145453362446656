import React from 'react'

import './CompareImage.css'

export default function CompareImageLoading({leftLabel, leftImage, rightLabel, rightImage})  {
	return (
		<div>
			<div>{leftLabel}: {leftImage != null ? '✅ Loaded' : '⌛️ Loading'}</div>
			<div>{rightLabel}: {rightImage != null ? '✅ Loaded' : '⌛️ Loading'}</div>
		</div>
	)
}
