import React, {useState, useEffect} from 'react'
import shajs from 'sha.js'

import './ReferenceImagePicker.css'

const ReferenceImagePicker = ({selectedImageKey, selectedReference, references, onSelectReference, startFolded, s3ImageManager}) => {
  const [folded, setFolded] = useState(startFolded)
	const [arrayReferencesThumbnails, setArrayReferencesThumbnails] = useState([])

  const onFold = e => {
    setFolded(!folded)
	}

	const onAddReference = () => {
		var input = document.createElement('input')
		input.type = 'file'
		input.multiple = 'multiple'
		input.accept = 'image/png, image/jpeg'
		
		input.onchange = e => { 
			s3ImageManager.uploadS3UserReferences(selectedImageKey, e.target.files)
		}
		
		input.click();		
	}

	const onClickReference = e => {
		onSelectReference(e.target.getAttribute('reference'))
	}

	useEffect(() => {
		if(selectedImageKey != null) {
			const imgs = []
			let first = true

			for(const r of references) {
				let computedClasses = first ? 'mainreference' : 'userreference'
				first = false

				if(r === selectedReference) {
					computedClasses += ' selected'
				}

				if(r in s3ImageManager.S3Images) {
					const id = 's3img_'+shajs('sha256').update(r).digest('hex')
					const img = <img className={computedClasses} alt="reference" id={id} key={r} reference={r} src={s3ImageManager.S3Images[r]} onClick={onClickReference}/>
					imgs.push(img)
				} else {
					s3ImageManager.loadS3Images(r)
				}
			}
			setArrayReferencesThumbnails(imgs)
		} else {
			setArrayReferencesThumbnails([])
		}

	}, [s3ImageManager.S3Images, selectedReference, references])

	return (
    <div className="ReferenceImagePicker">
      <div className={folded ? 'folded' : 'unfolded'}>
        <div className='title' onClick={onFold}>
          <div className='title-text'>Reference Images</div>
        </div>
        <div className='content'>
					<div className='image-array'>
	          {arrayReferencesThumbnails}
					</div>
					<div className='image-add'>
						<div className='button' onClick={onAddReference}>
							<div className='button-content'>
								<img alt="upload" src="upload-256x256.png"/>  ADD
							</div>
						</div>
					</div>
        </div>
      </div>
    </div>
	)
}

export default ReferenceImagePicker