import React from 'react'
import ConfigListCard from './ConfigListCard'
import './ConfigList.css'
import Spinner from '../Utils/Spinner'

const ConfigList = ({configs, onSelectConfig, selectedConfig, onPushChanges, onNewConfig, onDeleteConfig, onUploadAndReplaceConfig}) => {

  const onCardClick = e => {
    const cardname = e.target.getAttribute('name') ?? e.target.parentNode.getAttribute('name')
    onSelectConfig(cardname)
  }

  const cards = []
  for(const k of Object.keys(configs).sort()) {
    const card = <ConfigListCard key={k} name={k} config={configs[k]} onCardClick={onCardClick} isSelected={k===selectedConfig} onPushChanges={onPushChanges} onDeleteConfig={onDeleteConfig} onUploadAndReplaceConfig={onUploadAndReplaceConfig}/>
    cards.push(card)
  }

  let spinner = null
  if(cards.length === 0) {
    spinner = (<Spinner/>)
  }

  return (
    <div className='ConfigList'>
      {spinner}
			{cards}
      <div className='button' onClick={onNewConfig}><img className='addicon' alt='Add a new config file' src='add-256x256.png'/>New Config</div>
		</div>
  )
}

export default ConfigList