import React from 'react'
import CollapsibleImageTree from './CollapsibleImageTree';
import FoldableInformation from './FoldableInformation';
import { getPipelineStatusIcon, getPipelineAdjustedIID } from '../Utils/Utils'

import { JsonView, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

import './InformationPanel.css'

const PipelineNode = ({pipeline, differencesManager, s3ImageManager, urlManager}) => {
  const status = getPipelineStatusIcon(pipeline.status)

  return (
    <div className='PipelineNode'>
      <FoldableInformation title={`${status} Pipeline [${getPipelineAdjustedIID(pipeline)}]`} startFolded={false} canCopyToClipboard={true}>
        <JsonView
          data={pipeline || {}}
          shouldInitiallyExpand={(level) => level < 1}
          style={defaultStyles}
        />

        <CollapsibleImageTree
          IID={pipeline.iid}
          differencesManager={differencesManager}
          s3ImageManager={s3ImageManager}
          urlManager={urlManager}
        />
      </FoldableInformation>
    </div>
  )
}

export default PipelineNode