import React, {useState} from 'react'
import FoldableInformation from './FoldableInformation';
import ImageInspector from './ImageInspector'
import {useMountEffect} from '../Utils/Utils'
import CollapsibleImageTreeLine from './CollapsibleImageTreeLine';
import ImageThumbnail from './ImageThumbnail';

import './CollapsibleImageTree.css'

const CollapsibleImageTreeGame = ({IID, game, platform, path, differencesManager, s3ImageManager, onSetSelectedImage, urlManager}) => {
  const [folded, setFolded] = useState(() => {
    const url = urlManager.getURL()
    const isCurrentItem = ((IID.toString() === url.path.pipeline) && (platform === url.path.platform) & (game === url.path.game))
    return !isCurrentItem
  }) 

  const onToggleFold = () => {
    setFolded(!folded)
  }


  let images = null
  if(!folded) {
    images = s3ImageManager.S3Files[IID][platform][game]
    .filter(f => f.Key.search('_thumb.png')!==-1)
    .map(afile => {
      return(
        <ImageThumbnail
          key={afile.Key}
          IID={IID}
          game={game}
          platform={platform}
          s3File={afile}
          isLogOnly={false}
          differencesManager={differencesManager}
          s3ImageManager={s3ImageManager}
          onSetSelectedImage={onSetSelectedImage}
          urlManager={urlManager}
        />
      )
    })

    // Special case for log only ( No images )
    if(images.length === 0) {
      const logs = s3ImageManager.S3Files[IID][platform][game].filter(f => f.Key.match(/\.log$/))

      // Create a SINGLE log thumbnail, all the logs will be shown
      if(logs.length > 0) {
        images = (<ImageThumbnail
                    key={logs[0].Key}
                    IID={IID}
                    game={game}
                    platform={platform}
                    s3File={logs[0]}
                    isLogOnly={true}
                    differencesManager={differencesManager}
                    s3ImageManager={s3ImageManager}
                    onSetSelectedImage={onSetSelectedImage}
                    urlManager={urlManager}
                  />)
      }
    }
  }

  const platformHaveDifferences = differencesManager.doesElementHaveDifference(IID, platform, game) !== 'ok'

  return (
    <CollapsibleImageTreeLine additionalClasses='game' title={game} indent='1' hasDifference={platformHaveDifferences} startFolded={folded} onToggleFolded={onToggleFold}>
      <div className='images'>
        {images}
      </div>
    </CollapsibleImageTreeLine>
  )
}

const CollapsibleImageTreePlatform = ({IID, platform, path, differencesManager, s3ImageManager, onSetSelectedImage, urlManager}) => {
  const [folded, setFolded] = useState(false)

  const onToggleFold = () => {
    setFolded(!folded)
  }

  const cleanPlatformName = code => {
    let cleaned = code.toLowerCase()
    cleaned = code.replace('hd', '')
    return cleaned.toUpperCase()
  }

  let games = null
  if(folded === false) {
    games = Object.keys(s3ImageManager.S3Files[IID][platform]).map(game => {
      return (<CollapsibleImageTreeGame
        key={path + '_' + game}
        IID={IID}
        game={game}
        platform={platform}
        path={path + '_' + game}
        differencesManager={differencesManager}
        s3ImageManager={s3ImageManager}
        onSetSelectedImage={onSetSelectedImage}
        urlManager={urlManager}/>)
    })
  }

  const titleHaveDifferences = differencesManager.doesElementHaveDifference(IID, platform) !== 'ok'

  return (
    <CollapsibleImageTreeLine additionalClasses='platform' title={cleanPlatformName(platform)} indent='0' hasDifference={titleHaveDifferences} startFolded={folded} onToggleFolded={onToggleFold}>
      {games}
    </CollapsibleImageTreeLine>
  )
}

const CollapsibleImageTree = ({IID, differencesManager, s3ImageManager, urlManager}) => {
  const [showingImageInspector, setShowingImageInspector] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  useMountEffect(() => {
    const url = urlManager.getURL()
    if(url.path.pipeline === IID.toString()) {
        const urlImgID = url.path.imgid
        const urlImgKey = url.path.imgkey
        if(urlImgID != null && urlImgKey != null) {
        setSelectedImage({id:urlImgID, key:urlImgKey})
        setShowingImageInspector(true)
      }
    }
  })

  const onHideImageInspector = () => {
    const url = urlManager.getURL()
    delete(url.path['pipeline']) // this will remove all children of pipeline as well
    urlManager.setURL(url)

    setSelectedImage(null)
    setShowingImageInspector(false)
  }


  const onSetSelectedImage = (pipeline, game, platform, id, key) => {
    const url = urlManager.getURL()
    url.path.pipeline = pipeline
    url.path.game = game
    url.path.platform = platform
    url.path.imgid = id
    url.path.imgkey = key
    urlManager.setURL(url)

    setSelectedImage({id, key})
    setShowingImageInspector(true)
  }

  let platforms = null
  let imageInspector = null
  if(s3ImageManager.S3Files[IID] != null) {
    if('isEmpty' in s3ImageManager.S3Files[IID]) {
      platforms = (<div className='EmptyS3Files'>{s3ImageManager.S3Files[IID].message}</div>)
    } else {
      const platformKeys = Object.keys(s3ImageManager.S3Files[IID]).sort()
      platforms = platformKeys.map(platform => {
        return (<CollapsibleImageTreePlatform
                  key={IID + '_' + platform}
                  IID={IID}
                  platform={platform}
                  path={IID + '_' + platform}
                  differencesManager={differencesManager}
                  s3ImageManager={s3ImageManager}
                  onSetSelectedImage={onSetSelectedImage}
                  urlManager={urlManager}/>)
      })
    }

    imageInspector = (
      <ImageInspector
            show={showingImageInspector}
            selectedImage={selectedImage}
            onHideImageInspector={onHideImageInspector}
            s3ImageManager={s3ImageManager}
            urlManager={urlManager}
      />
    )
  }

  return (
    <div className="CollapsibleImageTree">
      <FoldableInformation title="Games" startOpen={true} canCopyToClipboard={false}>
          {platforms}
      </FoldableInformation>
      {imageInspector}
    </div>
  )
}

export default CollapsibleImageTree
