import React, {useEffect, useState} from 'react'
import Pixelmatch from 'pixelmatch'

import './CompareImage.css'

export default function CompareImageDifference({imgFirst, imgSecond})  {
  const [creatingDiff, setCreatingDiff] = useState(false)
  const [imgDiff, setImgDiff] = useState(null)

  useEffect(() => {
    // Function defined inside useEffect to satisfy the dependency array
    const createImageDiff = () => {
      if(imgDiff == null) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d', {willReadFrequently: true});

        const width = imgFirst.width
        const height = imgFirst.height
        canvas.width = width
        canvas.height = height

        context.drawImage(imgFirst, 0, 0)

        const dataRef = context.getImageData(0,0,width,height)

        context.drawImage(imgSecond, 0, 0)
        const dataTest = context.getImageData(0,0,width,height)

        const dataDiff = context.createImageData(width, height);

        Pixelmatch(dataRef.data, dataTest.data, dataDiff.data, width, height, {threshold: 0.01})

        context.putImageData(dataDiff, 0, 0)

        const img = new Image()
        // img.id = 'Diff/' + props.selectedImage.key
        img.src = canvas.toDataURL()

        setImgDiff(img)
      }
    }

		if(creatingDiff === false) {
      setCreatingDiff(true)
      setTimeout(createImageDiff, 1)
		}
	}, [imgFirst, imgSecond, creatingDiff, imgDiff])

	const onDownload = e => {
    if(imgDiff != null) {
      const link = document.createElement("a");
      link.download = 'Difference_'+imgSecond.id.replaceAll('/', '_')
      link.href = imgDiff.src
      link.click()
    }
	}

	const useWidth = Math.min(imgFirst.width, imgSecond.width, window.innerWidth * 0.85)
	const imgInsert = imgDiff!=null ? (<img alt="compare, pixel differences" src={imgDiff.src} width={useWidth+'px'}/>) : null

	return (
		<div className='CompareImageDifference'>
			<div className='header'>Difference<img alt="download icon" className="downloadicon" src="download-256x256.png" onClick={onDownload}/></div>
			{imgInsert}
		</div>
	)
}