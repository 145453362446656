import React, { useEffect, useState } from 'react'
import {useNavigate, useLocation, createSearchParams} from "react-router-dom";

import ConfigPanel from './ConfigPanel'
import ConfigList from './ConfigList'
import { v4 as uuidv4 } from 'uuid'
import {S3Init, S3CopyFile, S3DeleteFile, S3ListFiles, S3GetFile, S3PutFile} from '../Utils/AmazonS3'
import {GetCurrentLoggedInUsername} from '../Utils/User'

import './IndexConfigsPage.css'

const ConfigsPage = () => {
  // Path and Search Params to read/write the URL
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = createSearchParams(location.search)

  const [loading, setLoading] = useState(false)
  const [configs, setConfigs] = useState({})
  const [selectedConfig, setSelectedConfig] = useState(null)
  const [user, setUser] = useState('')
  const [settings, setSettings] = useState(null)

  useEffect(() => {
    if(!loading) {
      setLoading(true)

      if(user === '') {
        GetCurrentLoggedInUsername().then(username => setUser(username))
      }

      S3Init()
      .then(() => S3ListFiles('autotesting-configs', null, true))
      .then(files => {
        for(const f of files) {
          // Get only the .json files
          if(f.Key.match(/\.json$/) != null) {
            S3GetFile('autotesting-configs', f.Key)
            .then(data => {
              const jsonData = JSON.parse(data.toString('utf-8'))
              if(f.Key === 'settings.json') {
                setSettings(jsonData)
              } else {
                configs[f.Key] = fixAndUpdateConfig(jsonData)
                setConfigs({...configs})

                let urlConfig = searchParams.get('config')
                if(urlConfig != null) {
                  if(urlConfig in configs) {
                    setSelectedConfig(urlConfig)
                  }
                }
              }
            })
          }
        }
      })
    }
  }, [configs, loading])


  const defaultTestKeysValues = {
    'name': '',
    'comments': '',
    'enabled': true,
    'guid': null,
    'image_path': '',
    'platform': '',
    'region': '',
    'snapshot_path': '',
    'sub_type': '',
    'tags': [],
    'type': ''
  }
  const defaultTestKeys = Object.keys(defaultTestKeysValues)

  const defaultVariables = {
    'snapshots_root': '$AUTOTEST_DATA_ROOT/inputs',
    'images_root':'$AUTOTEST_IMAGE_ROOT'
  }

  const getNameFromImagePath = imagePath => {
    // ps1/fromship/SIEA/StarWarsRebelAssaultIITheHiddenEmpire_StarWars-RebelAssaultII-TheHiddenEmpire[USA][Disc2].bin
    // match 3 slashes, then get the first word
    const m = imagePath.match(/.*\/.*\/.*\/([^\W_]*)/)
    if(m != null) {
      const camelName = m[1]
      return camelName.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    }

    return '' 
  }

  const fixAndUpdateConfig = data => {
    // Do we have a variables sections
    if(!('variables' in data)) {
      data['variables'] = {}
    }

    // Do we have a tests sections
    if(!('tests' in data)) {
      data['tests'] = []
    }

    for(const t of data.tests) {
      for(const k of defaultTestKeys) {
        if(!(k in t)) {
          t[k] = defaultTestKeysValues[k]
        }
      }

      t['name'] = getNameFromImagePath(t.image_path)

      if(t['guid'] == null) {
        t['guid'] = uuidv4()
      }
    }

    data.tests.sort((a,b) => a.name.localeCompare(b.name))

    return data
  }

  const cleanConfigToPush = data => {
    for(const t of data.tests) {
      delete t.guid
    }
    return data
  }

  const onUpdateVariables = updatedVariables => {
    configs[selectedConfig].variables = {...updatedVariables}
    configs[selectedConfig].isDirty = true
    setConfigs({...configs})
  }

  const onAddTest = e => {
    e.stopPropagation()
    const newTest = {...defaultTestKeysValues}
    newTest.guid = uuidv4()
    newTest.isNew = true
    newTest.name = 'name is taken from image_path'
    const newTestArray = [newTest, ...configs[selectedConfig].tests]
    configs[selectedConfig].tests = newTestArray
    setConfigs({...configs})
  }

  const onUpdateTest = (updatedTest, isDeleting) => {
    const currentConfig = configs[selectedConfig]
    for(let i = 0; i < currentConfig.tests.length; i++) {
      if(currentConfig.tests[i].guid === updatedTest.guid) {
        if(isDeleting) {
          // keep the dirty flag if present or set it only if not deleting a new test.
          currentConfig.isDirty = currentConfig.isDirty || currentConfig.tests[i].isNew == null

          // Delete a single test
          currentConfig.tests.splice(i, 1)
        } else {
          delete updatedTest.isNew
          currentConfig.tests[i] = {...updatedTest}
          currentConfig.isDirty = true
        }
        break
      }
    }
    configs[selectedConfig] = {...currentConfig}
    setConfigs({...configs})
  }

  const onSelectConfig = newSelectedConfig => {
    if(newSelectedConfig == null) {
      searchParams.delete('config')
    } else {
      searchParams.set('config', newSelectedConfig)
    }
    navigate({ pathname: '/configs', search : searchParams.toString() })

    setSelectedConfig(newSelectedConfig)
  }

  const onPushChanges = (name, config) => {
    delete config.isDirty
    const cleanConfig = cleanConfigToPush(config) // remove the guids to export
    const backupName = `Backups/${new Date().toISOString()}_${user}_${name}`

    // Create a backup, then update the file
    S3CopyFile('autotesting-configs', name, 'autotesting-configs', backupName)
    .catch(error => {
      console.error('Could not create a backup of config file before updating. NOTE, this is normal if it`s a new file.', error)
    })
    .finally(() => {
      S3PutFile('autotesting-configs', name, JSON.stringify(cleanConfig, null, 2))
      .then(() => {
        configs[name] = fixAndUpdateConfig(config) // readd the guid for uniqueness
        configs[name]['updateTime'] = Date.now()
        setConfigs({...configs})
      })
    })
  }

  const onNewConfig = () => {
		let newConfigName = window.prompt('Enter the new config name')

		if(newConfigName != null) {
			newConfigName = newConfigName.replace(/\s/gi, '_');
			newConfigName = newConfigName.replace(/[^a-z0-9_]/gi, '');
			newConfigName = newConfigName.replace(/_+/gi, '_');

      if(newConfigName.match(/\.json$/) == null) {
        newConfigName = newConfigName + '.json' 
      }

			if(newConfigName.length > 0 && !(newConfigName in configs)) {
        const newConfig = {
          variables: {...defaultVariables},
          tests:[]
        }

        configs[newConfigName] = newConfig
        setConfigs({...configs})
        onSelectConfig(newConfigName)
			}
		}
  }

  const onUploadAndReplaceConfig = name => {
		var input = document.createElement('input')
		input.type = 'file'
		input.accept = 'application/json'
		
		input.onchange = e => { 
      if(e.target.files.length === 1) {
        let newconfig = null
        try {
          var reader = new FileReader();
          reader.readAsText(e.target.files[0],'UTF-8');
          reader.onload = readerEvent => {
            const content = JSON.parse(readerEvent.target.result)
            newconfig = fixAndUpdateConfig(content)

            configs[name] = newconfig
            configs[name].isDirty = true
            setConfigs({...configs})
          }
        } catch (e) {
          console.error('Selected JSON file is not valid for this application')
        }
      }
		}
		input.click();		
  }

  const onDeleteConfig = name => {
    if(name in configs) {

      if(window.confirm(`Warning you are about to DELETE the config suite named "${name}", we will try to make a backup on the server, but this is not reversible from this tool. Are you sure you want to continue?`) === true) {
        const backupName = `${new Date().toISOString()}_${name}.backup`

        // Create a backup, then update the file
        S3CopyFile('autotesting-configs', name, 'autotesting-configs', backupName)
        .then(() => {
          S3DeleteFile('autotesting-configs', name)
          .then(() => {
            delete configs[name]
            setConfigs({...configs})
            if(name === selectedConfig) {
              onSelectConfig(null)
            }
          })
        })
      }
    }
  }

  return (
    <div id='ConfigsPage'>
      <ConfigList 
        configs={configs} 
        onSelectConfig={onSelectConfig} 
        selectedConfig={selectedConfig} 
        onPushChanges={onPushChanges} 
        onNewConfig={onNewConfig} 
        onDeleteConfig={onDeleteConfig}
        onUploadAndReplaceConfig={onUploadAndReplaceConfig}/>
      <ConfigPanel 
        settings={settings}
        key={selectedConfig} 
        config={configs[selectedConfig]}
        tests={configs[selectedConfig] != null ? configs[selectedConfig].tests : null} 
        onUpdateTest={onUpdateTest} 
        onAddTest={onAddTest} 
        onUpdateVariables={onUpdateVariables}/>
    </div>
  )
}

export default ConfigsPage