import React from 'react'
import './CompareImage.css'

export default function CompareImageSideBySide({leftLabel, leftImage, rightLabel, rightImage})  {
	const onDownload = e => {
		const imgDownload = e.target.parentElement.innerText===leftLabel?leftImage:rightImage;
    if(imgDownload != null) {
      const link = document.createElement("a");
      link.download = imgDownload.id.replaceAll('/', '_')
      link.href = imgDownload.src
      link.click()
    }
	}

	const useWidth = window.innerWidth * 0.85 / 2.0
	return (
		<div className='CompareImageSideBySide'>
			<div className='header'>
				<div className='left'>{leftLabel}<img alt="download icon" className="downloadicon" src="download-256x256.png" onClick={onDownload}/></div>
				<div className='right'>{rightLabel}<img alt="download icon" className="downloadicon" src="download-256x256.png" onClick={onDownload}/></div>
			</div>
			<img alt="compare, left img" slot="first" src={leftImage.src} width={useWidth+'px'} />
			<img alt="compare, right img" slot="second" src={rightImage.src} width={useWidth+'px'} />
		</div>
	)
}
