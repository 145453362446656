import React from 'react'
import FoldableInformation from './FoldableInformation'
import PipelineNode from './PipelineNode'

import { JsonView, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

import './InformationPanel.css'

const InformationPanel = ({commitsManager, differencesManager, s3ImageManager, urlManager}) => {

  let pipelinesComponents = []
  if(commitsManager.selectedCommit != null && commitsManager.selectedCommit.pipelines != null) {
    const pipelinesIIDs = Object.keys(commitsManager.selectedCommit.pipelines).sort().reverse() // we want the newest on top
    pipelinesComponents = pipelinesIIDs.map(k => {
      return (<PipelineNode 
        key={k} 
        pipeline={commitsManager.selectedCommit.pipelines[k]} 
        differencesManager={differencesManager}
        s3ImageManager={s3ImageManager}
        urlManager={urlManager}
      />)
    })
  }
  
  return (
    <div className='InformationPanel'>
      <FoldableInformation title="Commit" startFolded={false} canCopyToClipboard={true}>
        <JsonView
          data={commitsManager.selectedCommit || {}}
          shouldInitiallyExpand={(level) => level < 1}
          style={defaultStyles}
        />
      </FoldableInformation>
      {pipelinesComponents}
    </div>
  )
}

export default InformationPanel