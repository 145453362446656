import React, { useState } from 'react'

import './Tags.css'

const Tags = ({id, tags, onModifyTags}) => {
	const [newTag, setNewTag] = useState('')

	const onChange = e => {
		// remove everthing non alpha numric
		const value = e.target.value.replace(/\W+/g, '') 
		setNewTag(value)
	}

	const onKeyDown = e => {
		if(e.target.name === 'newtag') {
			if(e.key === 'Enter' || e.key === ' ' || e.key === ',' || e.key === 'Tab') {
				const trimmed = newTag.trim()

				if(trimmed.length > 0) {
					// Make sure the tag is unique
					if(tags.indexOf(trimmed) === -1) {
						onModifyTags(id, [...tags, trimmed])
					}
					setNewTag('')
				}
			} else if (e.key === 'Escape') {
				setNewTag('')
			}
		}
	}

	const onDeleteTag = e => {
		const toDelete = e.target.getAttribute('tag')
		onModifyTags(id, tags.filter(t => t !== toDelete))
	}

	const alltags = []
	for(const t of tags) {
		const tag = (
			<div className='tag' key={t}>
				{t}
				<div className='delete' tag={t} onClick={onDeleteTag}>X</div>
			</div>
		)
		alltags.push(tag)
	}

	return (
		<div className='Tags'>
			{alltags}
			<input name='newtag' type='text' value={newTag} onKeyDown={onKeyDown} onChange={onChange}/>
		</div>
	)
}

export default Tags