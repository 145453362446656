import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import './NavBar.css'

function NavBarItem({title, to, isSelected}) {
  return (
    <Link to={to} reloadDocument={true}>
      <div className={isSelected ? 'NavBarItem selected' : 'NavBarItem'}>
        {title}
      </div>
    </Link>
  )
}

function NavBarName() {
  return (
    <div className='NavBarName'>
      <Link to='/' reloadDocument={true}>
        <img alt='Implicit Conversions Logo' src='logo180.png' /> 
      </Link>
    </div>
  )
}

function NavBarUser({user, signOut}) {
  const onSignOut = e => {
    e.stopPropagation()
    signOut()
  }

  return (
    <div className='NavBarUser'>
      <div className='username'>
        {user.attributes.email}
      </div>
      <div className='signout' onClick={onSignOut}>
        Sign Out
      </div>
    </div>
  )
}


export default function NavBar(props) {
  const location = useLocation()

  return (
    <div className='NavBar'>
      <NavBarName />
      <NavBarItem title='Testing' to='/tests' isSelected={location.pathname === '/tests' || location.pathname === '/'}/>
      <NavBarItem title='Configs' to='/configs' isSelected={location.pathname === '/configs'} />
      <NavBarItem title='About' to='/about' isSelected={location.pathname === '/about'} />
      <NavBarUser {...props}/>
    </div>
  )
}
