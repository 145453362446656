import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk'

const checkConnection = () => {
	if(AWS.config.credentials.expired) {
		return Amplify.Auth.currentCredentials().then(res => {
			AWS.config.update({
				region: 'us-west-2',
				credentials: res
			})
		})
	}
	return Promise.resolve()
}

export const S3Init = () => {
	return Amplify.Auth.currentCredentials().then(res => {
		AWS.config.update({
			region: 'us-west-2',
			credentials: res
		})
	})
}

export const S3ListFiles = async (bucket, prefix=null, rootOnly=false) => {
	await checkConnection()

	const s3 = new AWS.S3()
	const params = {
		Bucket:bucket,
	}

	if(prefix != null && prefix !== '') {
		params['Prefix'] = prefix
		if(prefix.match(/\/$/) == null) {
			params['Prefix'] += '/'
		}
	}

	if(rootOnly === true) {
		params['Delimiter'] = '/'
	}

	const files = []
	let isThereMorePages = true
	while(isThereMorePages) {
		let res = await s3.listObjectsV2(params).promise()
		files.push(...res.Contents)

		isThereMorePages = res.IsTruncated
		params.ContinuationToken = res.NextContinuationToken

		if(!isThereMorePages) {
			return files
		}
	}
}

export const S3GetFile = async (bucket, key) => {
	await checkConnection()

	const s3 = new AWS.S3()
	const params = {
		Bucket:bucket,
		Key:key
	}
	return s3.getObject(params).promise()
	.then(response => response.Body)
	.catch(error => console.error(`S3GetFile ERROR, ${bucket}  /  ${key}`, error))
}

export const S3CopyFile = async (bucket_src, key_src, bucket_dst, key_dst) => {
	await checkConnection()

	const s3 = new AWS.S3()
	const params = {
		CopySource: `${bucket_src}/${key_src}`,
		Bucket: bucket_dst,
		Key: key_dst
	}
	return s3.copyObject(params).promise()
}

export const S3PutFile = async (bucket, key, file) => {
	await checkConnection()

	const s3 = new AWS.S3()
	const params = {
		Bucket: bucket,
		Key: key,
		Body: file
	}
	return s3.upload(params).promise()
	.catch(error => console.error('S3PutFile ERROR', bucket, key, file, error))
}

export const S3DeleteFile = async (bucket, key) => {
	await checkConnection()

	const s3 = new AWS.S3()
	const params = {
		Bucket: bucket,
		Key: key,
	}
	return s3.deleteObject(params).promise()
	.catch(error => console.error('S3DeleteFile ERROR', bucket, key, error))
}