import React, {useEffect, useState} from 'react'
import Tags from './Tags' 
import {objectEquals} from '../Utils/Utils'
import './TestCard.css'

const TestCard = ({settings, testSettings, onUpdateTest, isNew}) => {
	const [isDirty, setIsDirty] = useState(isNew)
	const [isDeleting, setIsDeleting] = useState(false)
	const [test, setTest] = useState({...testSettings})

	useEffect(() => {
		setIsDirty(!objectEquals(testSettings, test) || isNew || isDeleting)
	}, [testSettings, test, isDeleting, isNew])

	const onChangeCard = e => {
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
		test[e.target.name] = value
		setTest({...test})
	}

	const onModifyTags = (id, tags) => {
		test[id] = tags
		setTest({...test})
	}

	const onDeleteTest = () => {
		if(isNew) {
			// new test, delete right away, no confirmation
			onUpdateTest(test, true)
		} else {
			setIsDeleting(!isDeleting)
		}
	}

	const onPushUpdate = () => {
		onUpdateTest(test, isDeleting)
	}

	let updateButton = null
	if(isDirty) {
		updateButton = (<div className='button' onClick={onPushUpdate}>Update</div>)
	}

	const platforms = settings.configs.platforms.map(r => {
		return (<option value={r.code}>{r.label}</option>)
	})

	const regions = settings.configs.regions.map(r => {
		return (<option value={r.code}>{r.label}</option>)
	})


	return (
		<div className={`TestCard ${(isDirty && !isDeleting)?'dirty':''} ${isDeleting?'deleting':''}`} key={test.guid}>
			<div className='row'>
				<div className='name'>{test.name}</div>
			</div>

			<div className='row'>
				<div className='label'>enabled:</div>
				<input name='enabled' type='checkbox' checked={test.enabled ? 'checked' : ''} onChange={onChangeCard}/>
			</div>

			<div className='row'>
				<div className='label'>tags:</div>
				<Tags id='tags' tags={test.tags} onModifyTags={onModifyTags} />
			</div>

			<div className='row'>
				<div className='label'>platform:</div>
				<select name='platform' value={test.platform} onChange={onChangeCard} >
					{platforms}
				</select>
			</div>

			<div className='row'>
				<div className='label'>region:</div>
				<select name='region' value={test.region} onChange={onChangeCard} >
					{regions}
				</select>
			</div>

			<div className='row'>
				<div className='label'>type:</div>
				<input name='type' type='text' value={test.type} onChange={onChangeCard}/>
			</div>

			<div className='row'>
				<div className='label'>sub_type:</div>
				<input name='sub_type' type='text' value={test.sub_type} onChange={onChangeCard}/>
			</div>

			<div className='row'>
				<div className='label'>snapshot_path:</div>
				<input name='snapshot_path' type='text' value={test.snapshot_path} onChange={onChangeCard}/>
			</div>

			<div className='row'>
				<div className='label'>image_path:</div>
				<input name='image_path' type='text' value={test.image_path} onChange={onChangeCard}/>
			</div>

			<div className='row'>
				<div className='label'>comments:</div>
				<textarea className='comments' id='comments' rows='5' name='comments' value={test.comments} onChange={onChangeCard} />
			</div>

			<div className='buttonRow'>
				<div className='button critical' onClick={onDeleteTest}>{isDeleting ? 'Cancel Delete' : 'Delete' }</div>			
				{updateButton}
			</div>
		</div>
  )
}

export default TestCard