import React from 'react'
import './Spinner.css'

const Spinner = () => {
	return (
		<div className="Spinner">
			<img alt='spinner' src='spinner.svg' />
		</div>)
}

export default Spinner