import React, {useState, useEffect} from 'react'
import './ConfigListCard.css'

const ConfigListCard = ({name, config, onCardClick, isSelected, onPushChanges, onDeleteConfig, onUploadAndReplaceConfig}) => {
	const [showingConfirmation, setShowingConfirmation] = useState(false)

	useEffect(() => {
		if('updateTime' in config && (Date.now() - config['updateTime'] < 3000)){
			setShowingConfirmation(true)
			setTimeout(() => {
				setShowingConfirmation(false)
			}, 4000);
		}
	})

	const onDownloadConfig = () => {
		const link = document.createElement("a");
		link.download = name
		link.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(config, null, 2))
		link.click()
	}

	const onPushChangesButton = e => {
		onPushChanges(name, config)
	}

	let pushChangesButton = null
	if('isDirty' in config && config['isDirty'] === true) {
		pushChangesButton = (<div className='button critical' onClick={onPushChangesButton}>Publish</div>)
	}

	return (
    <div className={'ConfigListCard' + (isSelected ? ' selected':'')} name={name} onClick={onCardClick}>
			<img className='deleteicon' alt='Delete this config file' src='minus-red-256x256.png' onClick={() => onDeleteConfig(name)}/>
			<div className='content' name={name}>
				<span className='title'>
					{name}
				</span>
				<span className='count'>
					{'Tests count: ' + (config['tests'].length ?? 0)}
				</span>
			</div>
			<img className='uploadicon' alt={`Upload and replace the config file named ${name}`} title={`Upload and replace the config file named ${name}`} src='upload-256x256.png' onClick={() => onUploadAndReplaceConfig(name)} />
			<img className='downloadicon' alt={`Download the config file named ${name}`} title={`Download the config file named ${name}`} src='download-256x256.png' onClick={onDownloadConfig} />
			<div className={showingConfirmation ? 'confirmationcheck' : 'confirmationcheck hidden'}>✅</div>
			{pushChangesButton}
		</div>
  )
}

export default ConfigListCard