import React from 'react'
import FoldableInformation from '../AutotestsPage/FoldableInformation'
import TestCard from './TestCard'

import './TestList.css'

const TestsList = ({settings, tests, onUpdateTest, onAddTest}) => {

	const testCards = []
	if(tests != null) {
		for(const t of tests) {
			const test = (<TestCard key={t.guid} settings={settings} testSettings={t} onUpdateTest={onUpdateTest} isNew={t.isNew} />)
			testCards.push(test)
		}
	}

	const testsList = (
		<div className='TestList'>
			{testCards}
		</div>
	)

	const addIcon = (<img className='addicon' alt='Add a new test' src='add-256x256.png' />)

	return (
		<div className='TestList'>
			<FoldableInformation title={`Tests (${tests.length})`} startFolded={false} canCopyToClipboard={false} children={testsList} actionIcon={addIcon} actionCallback={onAddTest}/>
		</div>
  )
}

export default TestsList