import React, { useEffect, useState } from 'react'
import {useNavigate, useLocation, createSearchParams} from "react-router-dom";

import AWS from 'aws-sdk'
import shajs from 'sha.js'
import { Octokit } from 'octokit';

import Config from '../Utils/Config';
import InformationPanel from './InformationPanel'
import CommitList from './CommitList'
import {useMountEffect, getShortTitleFromS3Key, getPlatformFromS3Key, getReferenceImagePathfromS3Key, getUserReferenceImagePathfromS3Key, getPipelineAdjustedIID } from '../Utils/Utils'
import {S3Init, S3ListFiles, S3GetFile, S3CopyFile, S3PutFile} from '../Utils/AmazonS3'

import './IndexAutotestsPage.css'

function AutotestsPage() {
  const [config, setConfig] = useState(null)
  const [initialFetchingInProgress, setInitialFetchingInProgress] = useState(false) // fence to prevent double fecthing in Dev

  // Path and Search Params to read/write the URL
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = createSearchParams(location.search)

  // App state
  const [fetchPage, setFetchPage] = useState(0)                   // Current GitHub REST API commits page to fetch
  const [selectedCommit, setSelectedCommit] = useState(null)      // Currently selected pipeline in the App
  const [selectedBranch, setSelectedBranch] = useState('main')    // Currently selected branches

  // GitHub data
  const [octokit, setOctokit] = useState(null)
  const [branches, setBranches] = useState([])                    // An array of the protected branches plus main branch
  const [commits, setCommits] = useState([])              

  // AWS data
  const [S3Files, setS3Files] = useState({})                      // Dict of files, grouped by: IID, Title, Platforms
  const [S3FilesByKey, setS3FilesByKey] = useState({})            // Dict of files, flat dict by unique key
  const [S3References, setS3References] = useState(null)          // Dict of files
  const [S3UserReferences, setS3UserReferences] = useState(null)  // Dict of files
  const [S3Images, setS3Images] = useState({})
  const [S3ImagesLoading, setS3ImagesLoading] = useState({})
  const [dynamoCompareData, setDynamoCompareData] = useState({})

  // These are the first steps
  // - Get the creds from the AWS Cognito Authenticated User
  // - Then get the configs for Gitlab from S3
  // This will run only if the config is null, but there is no reason to set
  // the config to null, unless we are login OUT
  useMountEffect(() => {
    if(config == null) {
      S3Init()
      .then(() => S3GetFile('imaginator-screenshots', 'autotesting-frontend-config.json'))
      .then(data => {
        const c = new Config('masticore')
        c.configs = JSON.parse(data.toString('utf-8'))
        setConfig(c)

        const ok = new Octokit({
          auth: c.GitHubAuth
        })
        setOctokit(ok)
      })
    }
  })

  // Second stage, list of branches
  useEffect(() => {
    if(config != null && octokit != null && !initialFetchingInProgress) {
      setInitialFetchingInProgress(true)

      loadS3ImageReferenceKeys()

      octokit.paginate('Get /repos/{owner}/{repo}/branches', {
        owner: "x-mastodon",
        repo: "masticore",
        protected: true,
        per_page: 100,
      }).then(branches => {
        setBranches(branches)

        const url = getURL()
        let urlSelectedBranch = url.path.branch || null
        if(urlSelectedBranch != null && branches.find(e => e.name === urlSelectedBranch) != null) {
          setSelectedBranch(urlSelectedBranch)
        } else {
          // This will set the branch to the default branch 'main'
          urlSelectedBranch = 'main'
          setURL({path:{branch:'main'}})
        }
      })
    }
  }, [initialFetchingInProgress, config])


  // Third stage, fetch 1 page of commits ( 10 ) for the selectedBranch
  // If there is a commit SHA on the URL, make sure this commit is appended to the list of commits
  // NOTE: to prevent GitLab API Rate limit trigger, I will not fetch all commits from now to the one selected
  // it could be 1000s of fetches if the commit is old, so, loading 10 current commit + the selected one if 
  // not present in the first page
  useEffect(() => {
    if(selectedBranch != null && branches.length > 0) {

      const url = getURL()
      const commitID = url.path.commit || null

      if(commitID == null) {
        getMoreCommits() // Get the first page of Commits
      } else { 
        findCommit(commitID)
      }
    }
  }, [branches, selectedBranch])


  const getMoreCommits = async _ => {
    if(selectedBranch != null && branches.length > 0) {
      const kCommitsCountToFetch = 10
      const pageToFetch = Math.floor(commits.length / kCommitsCountToFetch) + 1

      if(pageToFetch !== fetchPage) {
        setFetchPage(pageToFetch)

        return octokit.request('GET /repos/{owner}/{repo}/commits?sha={branch}', {
          owner: "x-mastodon",
          repo: "masticore",
          per_page: kCommitsCountToFetch,
          page: pageToFetch, 
          branch: selectedBranch,
          headers: {
            'X-GitHub-Api-Version': '2022-11-28'
          }
        })
        .then(newCommits => {
          const mergedCommits = [...commits, ...newCommits.data]
          setCommits(mergedCommits)
          return mergedCommits
        })
      }
    }
  }

  // Get a full page of Commits, for display, then get the commit itself, and insert it at the begining or the end of the list
  const findCommit = async id => {
    if(selectedBranch != null && branches.length > 0) {
      const allcommits = await getMoreCommits()

      let found = false
      for(const c of allcommits) {
        if(id === c.sha) {
          onSelectCommit(c)
          found = true
          break
        }
      }

      if(!found) {
        return octokit.request('GET /repos/{owner}/{repo}/commits?sha={commitid}', {
          owner: "x-mastodon",
          repo: "masticore",
          commitid: id,
          per_page: 1,
          headers: {
            'X-GitHub-Api-Version': '2022-11-28'
          }
        }).then(response => {
          if(response.data.length === 1) { 
            const urlCommit = response.data[0]
            allcommits.unshift(urlCommit)
            setCommits([...allcommits])
            onSelectCommit(urlCommit)
          }
        })
      }
    }
  }

  const getCommitPipelinesAndDifferences = async commit => {
    if(('pipelines' in commit) && ('differences' in commit)){
      return commit
    } else {
      commit['pipelines'] = {}
      commit['differences'] = {}
    }

    return octokit.request('GET /repos/{owner}/{repo}/actions/runs', {
      owner: 'x-mastodon',
      repo: 'masticore',
      head_sha: commit.sha,
      headers: {
        'X-GitHub-Api-Version': '2022-11-28'
      }
    }).then(response => {
      const allpromises = []

      for(const r of response.data.workflow_runs) {
        r.iid = getPipelineAdjustedIID(r)
        commit.pipelines[r.iid] = r
        allpromises.push(getDynamoDBDifferencesForIID(r.iid).then(data => commit.differences[r.iid] = data))
      }

  
      return Promise.all(allpromises).then(() => {
        const toUpdate = commits.find(c => c.sha === commit.sha)
        if(toUpdate != null) {
          Object.assign(toUpdate, commit)
          setCommits([...commits])
        }
        return commit
      })
    })
  }

  const loadS3ImagePipelineKeys = async pipeline => {
    if(! (pipeline.iid in S3Files)) {
      return S3ListFiles('imaginator-screenshots', pipeline.iid.toString())
      .then(files => {

        // // Test code to find a pipeline+game that has logs but no images
        // const stats = {}
        // for(const f of files) {
        //   const k = f.Key.split('/')[2]

        //   if(stats[k] == null) {
        //     stats[k] = {img:0, log:0}
        //   }

        //   const isLog = f.Key.match(/\.log$/)
        //   if(isLog) {
        //     stats[k].log++
        //   } else {
        //     stats[k].img++
        //   }
        // }

        // const keys = Object.keys(stats)
        // for(const k of keys) {
        //   if(stats[k].img == 0 && stats[k].log > 0) {
        //     console.log('Found one', k)
        //   }
        // }
        // // Test code to find a pipeline+game that has logs but no images

        // keep the composite images and all the logs
        const filteredFiles = files.filter(f => ((f.Key.search('/composite/') !==-1) || (f.Key.search(/log$/) !== -1)) )

        if(filteredFiles.length === 0) {
          S3Files[pipeline.iid] = {isEmpty:true, message:"This test didn't have any files on S3"}
          setS3Files({...S3Files})
        } else {
          const groupedFiles = groupFilesByPlatformAndTitle(filteredFiles)
          S3Files[pipeline.iid] = groupedFiles
          setS3Files({...S3Files})

          for(const f of filteredFiles) {
            S3FilesByKey[f.Key] = f
          }
          setS3FilesByKey({...S3FilesByKey})
        }
      })
    }
  }

  // {
  //  path:{pipeline, title, platform, imgid, imgkey}
  //  options:{filter, imageinspectortype}
  // }
  const urlSections = ['branch', 'commit', 'pipeline', 'platform', 'game', 'imgid', 'imgkey']
  const setURL = (params) => {
    if(params != null) {
      if('path' in params) {

        // delete all the path
        for(const s of urlSections) {
          searchParams.delete(s)
        }

        // set the path in order and stop on the first missing
        for(const s of urlSections) {
          if( !(s in params.path) || params.path[s] == null) {
            break
          }
          searchParams.set(s, params.path[s])
        }
      }

      if('options' in params) {
        const options = Object.keys(params.options)
        for(let o of options) {
          searchParams.set(o, params.options[o])
        }
      }

      navigate({ pathname: '/tests', search : searchParams.toString() })
    }
  }

  const getURL = () => {
    const path = {}
    const options = {}
    const allKeys = Array.from(searchParams.keys())

    for(let s of urlSections) {
      path[s] = searchParams.get(s)

      const idx = allKeys.indexOf(s)
      if(idx !== -1) {
        allKeys.splice(idx, 1)
      }
    }

    for(let k of allKeys) {
      options[k] = searchParams.get(k)
    }

    return {path, options}
  }

  // From all the filenames ( Keys ) organize them by platform first, then by title
  const groupFilesByPlatformAndTitle = (files) => {
    const data = {}
    files.forEach(f => {
      const platform = getPlatformFromS3Key(f.Key)
      const title = getShortTitleFromS3Key(f.Key)

      if(!(platform in data)) {
        data[platform] = {}
      }
      if(!(title in data[platform])) {
        data[platform][title] = []
      }

      data[platform][title].push(f)
    })
    return data
  }

  const groupDBCompareDataByPlatformAndTitle = (data) => {
    const res = {}
    res['Status'] = 'ok'

    for(const item of data.Items){
      const key = item.TestKey.S
      const title = getShortTitleFromS3Key(key)
      const platform = getPlatformFromS3Key(key)

      if(!(platform in res)) {
        res[platform] = {}
        res[platform]['Status'] = 'ok'
      }

      if(!(title in res[platform])) {
        res[platform][title] = {}
        res[platform][title]['Status'] = 'ok'
      }

      res[platform][title][key] = item.CompareResult.N

      if(parseInt(item.CompareResult.N) > 0){
        res[platform][title]['Status'] = 'differences'
        res[platform]['Status'] = 'differences'
        res['Status'] = 'differences'
      }
    }

    return res
  }

  // This encoder function is used to convert a string from an S3 file into a PNG image
  function encode(data)
  {
    /*
    function btoa(data: string): string (+2 overloads)
    Decodes a string into bytes using Latin-1 (ISO-8859), and encodes those bytes into a string using Base64.

    The data may be any JavaScript-value that can be coerced into a string.

    This function is only provided for compatibility with legacy web platform APIs and should never be used in new code,
    because they use strings to represent binary data and predate the introduction of typed arrays in JavaScript.
    For code running using Node.js APIs, converting between base64-encoded strings and binary data should be performed
    using Buffer.from(str, 'base64') andbuf.toString('base64').
    */
    var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
  }

  // Load S3 images from key URL
  const loadS3Images = async key => {
    if(!(key in S3Images) && !(key in S3ImagesLoading)) {
      S3ImagesLoading[key] = true
      setS3ImagesLoading(S3ImagesLoading)

      return S3GetFile('imaginator-screenshots', key)
      .then(data => {
        if(key.search(/log$/) !== -1) {
          // Logs
          S3Images[key] = data.toString('utf-8')
          setS3Images({...S3Images})
        } else {
          // Images
          const imgbin = 'data:image/png;base64,' + encode(data)

          S3Images[key] = imgbin
          setS3Images({...S3Images})

          const id = 's3img_'+shajs('sha256').update(key).digest('hex')
          const element = document.querySelector(`#${id}`)
          if(element != null) {
            element.innerHTML = `<img src="${imgbin}"/>`
          }
        }
      })
    }
  }

  const loadS3ImageReferenceKeys = async () => {
    if(S3References == null) {
      setS3References({})
      // Refefences are unique for each test
      return S3ListFiles('imaginator-screenshots', 'References').then(references => {
        const flatReferences = {}
        for(const r of references) {
          flatReferences[r.Key] = r
        }
        setS3References(flatReferences)
      })
    }

    if(S3UserReferences == null) {
      setS3UserReferences({})
      // UserReferences are uploaded by the users, they might not be related to the test
      S3ListFiles('imaginator-screenshots', 'UserReferences').then(references => {
        const flatReferences = {}
        for(const r of references) {
          flatReferences[r.Key] = r
        }
        setS3UserReferences(flatReferences)
      })
    }
  }


  const doesElementHaveDifference = (pipelineIID, platform, title, testKey) => {
    let res = 'ok'

    if(pipelineIID == null) { return res }
    if(pipelineIID in dynamoCompareData){
      res = dynamoCompareData[pipelineIID].Status
    } else {
      return 'missing'
    }
    if(res === 'ok' || platform == null) 
    { 
      return res
    }

    if(platform in dynamoCompareData[pipelineIID]) {
      res = dynamoCompareData[pipelineIID][platform].Status
    } else {
      return 'missing'
    }
    if(res === 'ok' || title == null) 
    { 
      return res
    }

    if(title in dynamoCompareData[pipelineIID][platform]) {
      res = dynamoCompareData[pipelineIID][platform][title].Status
    } else {
      return 'missing'
    }
    if(res === 'ok' || testKey == null) 
    { 
      return res
    }

    const dbKey = testKey.replaceAll('/', '_').replace('_thumb','')
    if(dbKey in dynamoCompareData[pipelineIID][platform][title]) {
      res = dynamoCompareData[pipelineIID][platform][title][dbKey] !== '0' ? 'differences' : 'ok'

      if(res === 'differences') {
        // Check if the reference image was updated after the test
        const imgKey = testKey.replace('_thumb','')
        const referenceKey = getReferenceImagePathfromS3Key(imgKey)
        if(S3References[referenceKey].LastModified > S3FilesByKey[imgKey].LastModified) {
          res = 'referenceupdated'
        }
      }
    } else {
      return 'missing'
    }

    return res
  }

  const getDynamoDBDifferencesForIID = async IID => {
    if(dynamoCompareData[IID] != null) {
      return dynamoCompareData[IID]
    } else {
      const db = new AWS.DynamoDB()

      const params = {
        TableName:'AutotestingCompareTestToReference',
        KeyConditionExpression:'IID = :iid',
        ExpressionAttributeValues:{
          ':iid': {'N': IID.toString()}
        }
      }

      return db.query(params).promise()
      .then((data, err) => {
        if(err != null) {
          console.error('Error fetching compare data from DB', err)
        } else {
          let cleanedData = {Status:'empty'}
          if(data.Count > 0) {
            cleanedData = groupDBCompareDataByPlatformAndTitle(data)
          }
          dynamoCompareData[IID] = cleanedData
          setDynamoCompareData({...dynamoCompareData})
          return dynamoCompareData[IID]
        }
      })
    }
  }

  const updateS3Reference = (src, dst) => {
    if( src == null ||
        dst == null ||
        src === '' ||
        dst === '' ||
        src.search('References') !== -1 ||
        dst.search('References') !== 0) {
      return Promise.reject('updateS3Reference ERROR, source or destination invalid')
    }

    return S3CopyFile('imaginator-screenshots', src, 'imaginator-screenshots', dst)
    .then((_data, err) => {
      if(err != null) {
        console.error('Couldnt update the reference image', err)
        return
      }
      S3References[dst].LastModified = Date.now()
      setS3References({...S3References})
    })
  }

  const uploadS3UserReferences = (testKey, files) => {
    const promises = []

    for(const f of files) {
      const key_dst = getUserReferenceImagePathfromS3Key(testKey, '_' + new Date().toISOString())
      promises.push(S3PutFile('imaginator-screenshots', key_dst, f))
    }

    Promise.all(promises).then(() => {
      setTimeout(() => {
        setS3UserReferences(null) // force reload of user references, in a couple of seconds
      }, 4000)
    })
  }

  const onSelectCommit = commit => {
    setSelectedCommit(commit)

    getCommitPipelinesAndDifferences(commit).then(updatedCommit => {
      Object.keys(updatedCommit.pipelines).map(k => {
        loadS3ImagePipelineKeys(updatedCommit.pipelines[k])
      })
    })

    const url = getURL()
    url.path['commit'] = commit.sha
    setURL(url)
  }

  const onSelectBranch = branch => {
    setURL({path:{branch}})
    setSelectedBranch(branch)
    setSelectedCommit(null)
    setCommits([])
    setFetchPage(0)
  }


  const branchesMananger = {
    branches, 
    selectedBranch,
    onSelectBranch,
  }

  const commitsManager = {
    commits, 
    selectedCommit,
    getMoreCommits,
    getCommitPipelinesAndDifferences,
    onSelectCommit, 
  }

  const differencesManager = {
    doesElementHaveDifference
  }

  const urlManager = {
    getURL, 
    setURL
  }

  const s3ImageManager = {
    S3Files,
    S3FilesByKey,
    S3References,
    S3UserReferences,

    S3Images,
  
    loadS3Images,
    updateS3Reference,
    uploadS3UserReferences,
  }

  return (
    <div id='AutotestsPage'>
        <CommitList
          branchesMananger={branchesMananger}
          commitsManager={commitsManager}
        />

        <InformationPanel
          commitsManager={commitsManager}
          differencesManager={differencesManager}
          s3ImageManager={s3ImageManager}
          urlManager={urlManager}
        />
    </div>
  )
}

export default AutotestsPage;
